import React, { useContext, useEffect, useState } from "react";
import App_url from "../components/common/Constant";
import { Carousel } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { ViewProductDetails, setLoader } from "../redux/actions/adminActions";
import { useDispatch, useSelector } from "react-redux";
import { SocketConnect } from "../context/context";
import wsSend_request from "../components/common/WsSendRequest";
import Loader from "../components/common/Loader";

const HistoryDetail = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth" // Smooth scroll
    });
  };
  useEffect(()=>{scrollToTop()},[])
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { connect } = useContext(SocketConnect);

  const { productDetail, loader, selfInfo } = useSelector(
    (state) => state.adminReducers
  );
  useEffect(() => {
    dispatch(ViewProductDetails(""));
    dispatch(setLoader(true))

    const param = {
      transmit: "broadcast",
      type: App_url?.api?.getWebinarDetail,
      payload: { webinarId: id },
    };
    wsSend_request(connect, param);
    setTimeout(() => {
      dispatch(setLoader(false));
      }, 600);
  }, [id,connect,dispatch]);

  const [arrayOfObjects, setArrayOfObjects] = useState("");
  const [selectedObjects, setSelectedObjects] = useState([]);
  const handleRoute = (type) => {
    navigate(type);
  };
  const [closedCount, setClosedBookingsCount] = useState(0);
  console.log("closedCount",closedCount,"selectedObjects",selectedObjects)
  useEffect(() => {
    if (productDetail?.booking?.length) {
      const updatedBookings = productDetail.booking.map((item) => {
        if (item.status === "closed" && item.userId === selfInfo?.id) {
          return { ...item, status: "own" };
        }
        return item;
      });
      setArrayOfObjects(updatedBookings);

      // Filter the items where status is "closed"
      const closedBookingsCount = productDetail.booking.filter(
        (item) => item?.status === "closed"
      ).length;
      setClosedBookingsCount(closedBookingsCount);
    }
  }, [productDetail?.booking,selfInfo?.id]);

  const handleSelect = (data) => {
    if (data?.status === "open" || data?.status === "selected") {
      const index = arrayOfObjects.findIndex(
        (obj) => obj.serial_number === data?.serial_number
      );
      if (index !== -1) {
        if (data.status === "open") {
          setSelectedObjects((prevSelected) => [
            ...prevSelected,
            arrayOfObjects[index],
          ]);
          arrayOfObjects[index] = {
            ...arrayOfObjects[index],
            status: "selected",
          };
        } else if (data.status === "selected") {
          setSelectedObjects((prevSelected) =>
            prevSelected.filter(
              (item) => item.serial_number !== data.serial_number
            )
          );
          arrayOfObjects[index] = { ...arrayOfObjects[index], status: "open" };
        }
        setArrayOfObjects([...arrayOfObjects]);
      }
    }
  };
  return (
    <div className="">
      <div className="m-0 p-3 overflow-hidden">
        <div className="col-md-12 white-background">
          <div className="col-md-12">
            <img
              src={App_url?.image?.bgDual}
              alt="background header"
              loading='lazy'
              width={"100%"}
              className="header-text-img"
            />
            <div className="d-flex aic ms-0 ms-md-2 header-text">
              <img
                src={App_url?.image?.CaretLeft}
                alt="left arrow"
                loading='lazy'
                className="mb-2 pointer z-100"
                onClick={() => {
                  handleRoute(App_url?.link?.history);
                }}
              />
              <h5 className="fw-500 fs-18px z-100">See Result</h5>
            </div>
          </div>
          <div className="col-md-12 mt-4 p-0 ps-3 manage-md-pt-3">
            <div className="d-flex aic jcsb py-2">
              <h6 className="ms-2 pl-4px z-100 title">
                {productDetail?.data?.webinar_title}
              </h6>
              <h6 className="pe-md-4 pe-0 z-100 title">
                {" "}
                {productDetail?.data?.winner_number
                  ? `Winner : ${productDetail?.data?.winner_number} Number`
                  : "Winner not disclosed yet"}{" "}
              </h6>
            </div>
        {loader ? <div className='relative h-100px mt-5'> <Loader/> </div> : (<>

            <div className="row m-0">
              <div className="col-md-12">
                <div className="row m-0 col-reverse-sm">
                  <div className="col-12 col-md-7">
                    <div className="row">
                      <h5
                        className="fw-500 mt-3"
                        style={{ marginLeft: "-0.7rem" }}
                      >
                        Ticket Book{" "}
                      </h5>
                    </div>
                    <div className="row">
                      {arrayOfObjects?.length > 0 &&
                        arrayOfObjects?.map((item, index) => {
                          return (
                            <div
                              className={`col-md-2 col-lg-1 col-2-5 mx-1 my-1 selection-box ${
                                item?.serial_number ===
                                parseInt(productDetail?.data?.winner_number)
                                  ? "winner"
                                  : ""
                              } ${
                                item?.status === "hold" ||
                                item?.status === "own" ||
                                item?.className === "confirmed"
                                  ? "cursor-not-allowed"
                                  : "pointer"
                              } p-2 aic rounded ${item?.status}`}
                              key={index}
                              onClick={() => {
                                handleSelect(item);
                              }}
                            >
                              <div className="d-flex flex-column">
                                <span className="text-left fw-600">
                                  {item?.serial_number}
                                </span>
                                <span className="word-wrap-break-word">
                                  {item?.status === "selected"
                                    ? "Selected"
                                    : item?.status === "hold"
                                    ? "In a Cart"
                                    : item?.status === "closed"
                                    ? item?.user_name
                                    : item?.status === "own"
                                    ? `${item?.user_name}`
                                    : ""}
                                </span>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  <div className="col-md-5 col-12 p-0 mb-mt-0 mt-4">
                    <div className="col-md-12 col-12">
                      <div className="d-flex aic gap-2 flex-wrap mb-2">
                        <Carousel controls={true}>
                          {productDetail?.data?.product_images !== undefined &&
                            productDetail?.data?.product_images?.length > 0 &&
                            productDetail?.data?.product_images?.map(
                              (image, index) => (
                                <Carousel.Item key={index}>
                                  <div className="carousel-image-container pointer">
                                    <img
                                      src={`${App_url?.BACKEND_URL}uploads/${image}`}
                                      className="rounded"
                                      alt="product"
                                      loading='lazy'
                                      width={
                                        window.screen.width > 620
                                          ? "410px"
                                          : "100%"
                                      }
                                      height={"200px"}
                                    />
                                  </div>
                                </Carousel.Item>
                              )
                            )}
                        </Carousel>
                      </div>
                      <h5 className="text-yellow fw-5">
                        Cost: {productDetail?.data?.product_price}$
                      </h5>
                      <h5 className="fw-5">Description:</h5>
                      <h6 className="col-12 col-lg-10 description" dangerouslySetInnerHTML={{ __html: productDetail?.data?.description }} />
                    </div>
                  </div>
                </div>
              </div>
            </div></>)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoryDetail;
