
const App_url = {
    image: {
        logo: `${window.location.origin}/assets/images/logo.png`,
        bg: `${window.location.origin}/assets/images/bg.svg`,
        bgHeader: `${window.location.origin}/assets/images/bgHeader.svg`,
        bulletTravel: `${window.location.origin}/assets/images/bulletTravel.svg`,
        checkIcon: `${window.location.origin}/assets/images/checkIcon.svg`,
        FileText: `${window.location.origin}/assets/images/FileText.svg`,
        gun: `${window.location.origin}/assets/images/gun.svg`,
        gunShot: `${window.location.origin}/assets/images/gunShot.svg`,
        cashApp: `${window.location.origin}/assets/images/cashApp.svg`,
        paypal: `${window.location.origin}/assets/images/paypal.svg`,
        vemo: `${window.location.origin}/assets/images/vemo.svg`,
        discard: `${window.location.origin}/assets/images/discard.svg`,
        webinarPrice: `${window.location.origin}/assets/images/webinarPrice.svg`,
        webinarDate: `${window.location.origin}/assets/images/webinarDate.svg`,
        webinarName: `${window.location.origin}/assets/images/webinarName.svg`,
        CaretLeft: `${window.location.origin}/assets/images/CaretLeft.svg`,
        ArrowLeft: `${window.location.origin}/assets/images/ArrowLeft.svg`,
        paymentSuccess: `${window.location.origin}/assets/images/paymentSuccess.svg`,
        bgSecond: `${window.location.origin}/assets/images/bgSecond.svg`,
        checked: `${window.location.origin}/assets/images/checked.svg`,
        unchecked: `${window.location.origin}/assets/images/unchecked.svg`,
        bgDual: `${window.location.origin}/assets/images/bgDual.svg`,
        Eye: `${window.location.origin}/assets/images/Eye.svg`,
        EyeSlash: `${window.location.origin}/assets/images/EyeSlash.svg`,
        logOut: `${window.location.origin}/assets/images/logOut.svg`,

    },
    link: {
        signIn:"/signin",
        signUp:"/signup",
        home:"/home",
        detail:"/webinar-detail",
        history:"/history",
        historyDetail:"/history-detail",
        payment:"/payment",
    paymentIntent : "payment/payment-intent",
    },
    api:{
        signUp:"addcustomeruser",
        signIn:"userlogin",
        getWebinarList:"get_all_webinar",
        getWebinarDetail:"get_single_webinar",
        addBooking:"add_booking",
        updateBooking:"update_booking",
        logOut:"userlogout",
        createOrder:"stripe/create_order",
        captureOrder:"stripe/capture_order"        
    },
    BACKEND_URL: process.env.REACT_APP_API_URL,
    PAYPAL_CLIENT_ID: process.env.REACT_APP_PAYPAL_CLIENT_ID,
}
export default App_url;

export function uuidv4() { 
    let d = new Date().getTime();
    let d2 = (
        (typeof performance !== 'undefined') && 
        performance.now && 
        (performance.now() * 1000)
    ) || 0; // Time in microseconds since page-load or 0 if unsupported
    
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = Math.random() * 16; // random number between 0 and 16
        if (d > 0) { // Use timestamp until depleted
            r = ((d + r) % 16) | 0;
            d = Math.floor(d / 16);
        } else { // Use microseconds since page-load if supported
            r = ((d2 + r) % 16) | 0;
            d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : ((r & 0x3) | 0x8)).toString(16);
    });
}


export const historyBack = () => {
window.history.back();
}
export function truncateString(str, count) {
    if(!str || !count){
        return
    }
    if (str.length <= count) {
        return str; // If the string is already shorter than or equal to the count, return the original string
    } else {
        return str.slice(0, count) + '...'; // Truncate the string to the specified count and append '...'
    }
}
export function isTimeDifferenceGreaterThanFiveMinutes(updatedTime) {
    // Convert the updatedTime to a Date object in UTC
    const updatedDate = new Date(updatedTime);
    const updatedUTCTime = new Date(updatedDate.toUTCString());

    // Get the current time in UTC
    const currentTime = new Date();
    const currentUTCTime = new Date(currentTime.toUTCString());

    // Calculate the time difference in milliseconds
    const timeDifference = currentUTCTime.getTime() - updatedUTCTime.getTime();

    // Convert the time difference to minutes
    const timeDifferenceInMinutes = timeDifference / (1000 * 60);

    return timeDifferenceInMinutes >= 3;
}
