/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { PostRequestAPI } from "../common/PostRequest";
import App_url from "../common/Constant";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setShowSuccessModal } from "../../redux/actions/adminActions";

export default function Checkout(props) {
  const naigate = useNavigate()
  const dispatch = useDispatch()
  console.log("Checkout",props)
  const { accessToken } = useSelector(
    (state) => state.adminReducers
  );
  const stripe = useStripe();
  const elements = useElements();
  const handleClose = async () => {
    props.onClose();
  };
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [formData, setFormData] = useState(null);
  console.log("formData",formData)
  useEffect(() => {
    if (!stripe) {
      return console.log("stripe error");
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const unloadHandler = async () => {
    // TODO document why this async arrow function is empty

    if (customer?.payment_id) {
      return await props?.callCancelPaymentIntent(customer?.payment_id);
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (props?.customerDetails) {
      const res = props?.customerDetails;
      setCustomer(res);
    }
  }, []);
  useEffect(() => {
    if (props?.formData) {
      const res = props?.formData;
      setFormData(res);
    }
  }, []);
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isLoading) {
        const message =
          "Your payment is currently in process. Are you sure you want to leave this page?";
        event.returnValue = message;
        return message;
      }
    };

    const popstateHandler = (event) => {
      // Handle the popstate event (user navigating back or forward)
      // You can perform additional actions here if needed
      console.log("Popstate event triggered");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("popstate", popstateHandler);
    window.addEventListener("unload", unloadHandler);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", popstateHandler);
      window.removeEventListener("unload", unloadHandler);
    };
  }, [isLoading]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return console.log("Connection or Popup error");
    }
    setMessage("");
    setIsLoading(true);
    const paymentResponse = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/payment-success/${customer?.strip_customer_id}/${customer?.donation_amount}/${customer?.payment_id}`,
      },
      redirect: "if_required",
    });
    if (paymentResponse?.paymentIntent?.id && !paymentResponse?.error) {
      localStorage.setItem("cancel-payment-intent", "");
      console.log("Verify Connection", paymentResponse);

      return await callVerityPayment(paymentResponse?.paymentIntent);
    } else {
      console.log("paymentResponse", paymentResponse);
    }
    const { error } = paymentResponse;
    console.log("error", error);
    if (
      error?.type === "card_error" ||
      error?.type === "validation_error" ||
      error?.message
    ) {
      if (error?.message !== "Your card number is incomplete.") {
        setMessage(error?.message);
      }
    } else {
      setMessage("An unexpected error occurred.");
    }
    setIsLoading(false);
  };
  const callVerityPayment = async (payment) => {
    const payload = {
      ...props?.data?.payload?.payload,
      customer_id: customer?.strip_customer_id,
      amount: customer?.donation_amount,
      payment_intent_client_secret: payment?.client_secret,
      payment_intent: payment?.id,
      payment_id: customer?.payment_id,
      orderID:props?.data?.payload?.id,
    };
    // console.log("payment?.id",payment?.id)
    const response = await PostRequestAPI(App_url?.api?.captureOrder, payload,accessToken);
    if (response?.status === 200) {
      dispatch(
        setShowSuccessModal({
          show: "BOOKINGSUCCESS",
          data: payload?.price,
          msg:false
        })
      );
    } else {
      if (response?.data?.message) {
        toast.info(response?.data?.message);
      }
      naigate(App_url?.link?.home)
    }
    console.log("response", response);
    setIsLoading(false);
    handleClose();
  };
  const paymentElementOptions = {
    layout: "tabs",
  };
  return (
    <Modal
      show={props?.data?.show && props?.data?.type === "payment"}
      onHide={handleClose}
      animation={true}
      centered
    >
      <Modal.Header closeButton onClick={handleClose} className="bg-grey">
      <h4>Pay ${parseFloat(props?.data?.payload?.amount / 100).toFixed(2)} Instantly</h4>
      </Modal.Header>
      <form id="payment-form" onSubmit={handleSubmit} className="p-2">
        <PaymentElement
          onChange={console.log}
          id="payment-element"
          options={paymentElementOptions}
          amount={props?.data?.payload?.amount / 100}
        />
        {message && (
          <div id="payment-message" className="text-danger">
            {message}
          </div>
        )}
        <div className="d-flex gap-2 align-items-center mt-4 mb-2">
          <button
            disabled={isLoading || !stripe || !elements}
            type="submit"
            className={`btn btn-primary ${
              isLoading ? "stripe-loader" : ""
            } button mb-0`}
          >
            {isLoading ? (
              <React.Fragment>
                Payment in Progress <span class="btn-ring"></span>
              </React.Fragment>
            ) : (
              "Submit Payment"
            )}
          </button>{" "}
          <button
            href="#"
            onClick={() => handleClose()}
            className="btn btn-secondary"
          >
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
}
