import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setShowSuccessModal } from "../../../redux/actions/adminActions";
import App_url from "../Constant";
import { useNavigate } from "react-router-dom";

export default function BookingSuccessModal({
  handleClose = () => {},
  show = false,
  data = "",
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { successModalData } = useSelector((state) => state.adminReducers);
  console.log("successModalData", successModalData);
  const CloseData = async () => {
    if (successModalData?.callBack) {
      await successModalData?.callBack();
    }
    dispatch(
      setShowSuccessModal({
        show: "",
        data: "",
      })
    );
    navigate(App_url?.link?.home);
  };

  return (
    <Modal
      show={successModalData?.show === "BOOKINGSUCCESS"}
      centered
      onHide={CloseData}
    >
      <Modal.Body className="modal-body d-center flex-column gap-2">
        <h5 className="fw-600">210 Armory</h5>
        <img src={App_url?.image?.paymentSuccess} alt="" />

        <h6 className="my-3 text-center w-75">
          Thank you for your Webinar Purchase.
          {successModalData?.data?.msg?.payment_type === "CASHAPP" ? (
            <>
              <div className="mt-1">
                Please send CashApp to <span className="fw-6">$210Armory</span>
              </div>
            </>
          ) : (
            <>
              {successModalData?.data?.msg?.payment_type === "ZELLE" ? (
                <>
                  <div className="mt-1">
                    Please send Zelle to{" "}
                    <span className="fw-6">Jaime Mallen</span>
                  </div>
                  <div className="mt-1"><span className="fw-6">830-765-5874</span></div>
                </>
              ) : (
                <>
                  {successModalData?.data?.msg?.payment_type === "VENMO" && (
                    <>
                      <div className="mt-1">
                        Please send Venmo
                         to
                      </div>
                      <div className="mt-1"><span className="fw-6">@Jaime-Mallen</span></div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </h6>
        <h3 className="text-money fw-6">
          ${successModalData?.data?.data || successModalData?.data}
        </h3>
        <button
          className="yellow-bg fw-500 btn w-100  gap-2 d-center"
          onClick={CloseData}
        >
          <span>
            <img src={App_url?.image?.ArrowLeft} alt="" />
          </span>
          <span>Go Back</span>
        </button>
      </Modal.Body>
    </Modal>
  );
}

BookingSuccessModal.propTypes = {
  handleClose: PropTypes.func,
  show: PropTypes.bool,
  data: PropTypes.any,
};
