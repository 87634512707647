/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import App_url, {
  isTimeDifferenceGreaterThanFiveMinutes,
  uuidv4,
} from "../components/common/Constant";
import { Breadcrumb, Carousel } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { setLoader } from "../redux/actions/adminActions";
import { useDispatch, useSelector } from "react-redux";
import BookingSuccessModal from "../components/common/Modal/BookingSuccessModal";
import { SocketConnect } from "../context/context";
import wsSend_request from "../components/common/WsSendRequest";
import { toast } from "react-toastify";
import Loader from "../components/common/Loader";
import { PostRequestAPI } from "../components/common/PostRequest";
// import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
// import CashApp from "../components/common/CashApp";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Checkout from "../components/common/Checkout";

// paypal start
// function Message({ content }) {
//   return <p>{content}</p>;
// }
// paypal end

const Detail = () => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  const [clientSecretKey, setClientSecretKey] = useState("");
  const [showPaymentModal,setShowPaymentModal] = useState(true)
console.log("clientSecretKey",clientSecretKey,stripePromise,process.env.REACT_APP_STRIPE_KEY)
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  const [showPayment, setShowPayment] = useState(false);
  // paypal start
  // const [message, setMessage] = useState("");
  // const [paymentDetails, setPaymentDetails] = useState("");
  // paypal end
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { connect } = useContext(SocketConnect);

  const { productDetail, loader, selfInfo, accessToken } = useSelector(
    (state) => state.adminReducers
  );
  useEffect(() => {
    dispatch(setLoader(true));

    const param = {
      transmit: "broadcast",
      type: App_url?.api?.getWebinarDetail,
      payload: { webinarId: id },
    };
    wsSend_request(connect, param);
    setTimeout(() => {
      dispatch(setLoader(false));
    }, 600);
  }, [id, connect, dispatch, showPayment]);

  const [arrayOfObjects, setArrayOfObjects] = useState("");
  const [selectedObjects, setSelectedObjects] = useState([]);
  useEffect(() => {
    const preCart = productDetail?.booking?.filter(
      (item) => item?.status === "hold" && item?.userId === selfInfo?.id
    );
    setSelectedObjects(preCart);
  }, [productDetail, productDetail?.booking, selfInfo?.id]);
  useEffect(() => {
    if (selectedObjects?.length > 0) {
      const bookings = productDetail?.booking || [];
      const filteredObjects = selectedObjects?.filter((selectedObject) => {
        const hasHoldBooking = bookings.some(
          (booking) =>
            booking.serial_number === selectedObject.serial_number &&
            booking.status === "hold"
          // booking?.userId == selfInfo?.id
        );
        return !hasHoldBooking;
      });
      if (filteredObjects?.length > 0) {
        setSelectedObjects(filteredObjects);
      }
    }
  }, [productDetail, productDetail?.booking]);

  const handleRoute = (type) => {
    navigate(type);
  };
  const [closedCount, setClosedBookingsCount] = useState(0);
  useEffect(() => {
    if (productDetail?.booking?.length > 0) {
      const updatedBookings = productDetail?.booking?.map((item) => {
        if (item.status === "closed" && item.userId === selfInfo?.id) {
          return { ...item, status: "own" };
        }
        return item;
      });
      setArrayOfObjects(updatedBookings);

      // Filter the items where status is "closed"
      const closedBookingsCount = productDetail?.booking?.filter(
        (item) => item?.status === "closed"
      ).length;
      setClosedBookingsCount(closedBookingsCount);
    }
    const slots = (productDetail?.booking ?? [])
      .filter(
        (item) =>
          isTimeDifferenceGreaterThanFiveMinutes(item?.updatedAt) &&
          item?.status === "hold"
      )
      .map((item) => item?.serial_number);

    if (slots?.length > 0) {
      const param = {
        transmit: "broadcast",
        type: App_url?.api?.updateBooking,
        payload: {
          webinarId: id,
          serialNumbers: slots,
          userId: selfInfo?.id,
        },
      };
      wsSend_request(connect, param);
      dispatch(setLoader(true));

      const paramreq = {
        transmit: "broadcast",
        type: App_url?.api?.getWebinarDetail,
        payload: { webinarId: id },
      };
      wsSend_request(connect, paramreq);
      setTimeout(() => {
        dispatch(setLoader(false));
      }, 600);
    }
  }, [
    productDetail?.booking,
    productDetail?.booking?.length,
    connect,
    dispatch,
    id,
    selfInfo?.id,
  ]);

  const [formData, setFormData] = useState({
    payment: "Venmo",
    amount: 0,
    offerPrice: 0,
    title: "",
    offer: "",
  });
  const showSignInSuccessModal = async () => {
    const serialArray = [];
    selectedObjects.forEach((item) => {
      serialArray.push(item?.serial_number);
    });
    const param = {
      payload: {
        webinarId: id,
        serialNumbers: serialArray,
        userId: selfInfo?.id,
        status: "closed",
        payment_type: formData?.payment,
        offer: formData?.offer || "",
        price: formData?.offerPrice || formData?.amount,
      },
    };
    const responsePayment = await PostRequestAPI(
      App_url?.api?.createOrder,
      param?.payload,
      accessToken
    );
    if (responsePayment?.data?.id) {
      // setPaymentDetails(responsePayment?.data);
      return responsePayment?.data?.id;
    } else {
      return null;
    }
    // wsSend_request(connect, param);
  };
  const handleCODOrder = async (e, type) => {
    e.preventDefault();
    const serialArray = [];
    selectedObjects.forEach((item) => {
      serialArray.push(item?.serial_number);
    });
    const param = {
      transmit: "broadcast",
      type: App_url?.api?.updateBooking,
      payload: {
        webinarId: id,
        serialNumbers: serialArray,
        userId: selfInfo?.id,
        status: "closed",
        payment_type: type,
        offer: formData?.offer || "",
        price: formData?.offerPrice || formData?.amount,
        transaction_id: uuidv4(),
      },
    };
    wsSend_request(connect, param);
  };
  const width = window.screen.width;

  const handleSelect = (data) => {
    if (data?.status === "open" || data?.status === "selected") {
      const index = arrayOfObjects.findIndex(
        (obj) => obj.serial_number === data?.serial_number
      );
      if (index !== -1) {
        if (data.status === "open") {
          setSelectedObjects((prevSelected) => [
            ...prevSelected,
            arrayOfObjects[index],
          ]);
          arrayOfObjects[index] = {
            ...arrayOfObjects[index],
            status: "selected",
          };
        } else if (data.status === "selected") {
          setSelectedObjects((prevSelected) =>
            prevSelected.filter(
              (item) => item.serial_number !== data.serial_number
            )
          );
          arrayOfObjects[index] = { ...arrayOfObjects[index], status: "open" };
        }
        setArrayOfObjects([...arrayOfObjects]);
      }
    }
  };
  const handleBookingChange = async () => {
    if (
      parseInt(productDetail?.booking?.length - closedCount) === 0 ||
      productDetail?.data?.winner_number
    )
      return;
    if (!selectedObjects?.length) {
      toast.error("Please select seats");
      return;
    }
    const bookedSeats = new Set(
      productDetail?.booking
        ?.filter((item) => item?.order_id)
        ?.map((item) => item.order_id)
    );
    if (
      bookedSeats?.size < 10 &&
      selectedObjects?.length >= 4 &&
      productDetail?.data?.offer === "3+1"
    ) {
      const divider = parseInt(selectedObjects?.length / 4);
      setFormData((prev) => ({
        ...prev,
        offer: productDetail?.data?.offer,
        offerPrice: parseFloat(
          parseFloat(
            productDetail?.data?.ticket_price * selectedObjects?.length
          ) - parseFloat(productDetail?.data?.ticket_price * divider)
        ),
      }));
    } else if (
      selectedObjects?.length > 1 &&
      productDetail?.data?.offer?.length &&
      productDetail?.data?.offer !== "3+1"
    ) {
      setFormData((prev) => ({
        ...prev,
        offer: productDetail?.data?.offer,
        offerPrice:
          parseFloat(
            productDetail?.data?.ticket_price * selectedObjects?.length
          ) -
          selectedObjects?.length * parseInt(productDetail?.data?.offer),
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        offer: "",
        offerPrice: productDetail?.data?.ticket_price * selectedObjects?.length,
      }));
    }

    const serialArray = [];
    selectedObjects.forEach((item) => {
      serialArray.push(item?.serial_number);
    });
    const param = {
      transmit: "broadcast",
      type: App_url?.api?.addBooking,
      payload: {
        userId: selfInfo?.id,
        webinarId: id,
        serialNumbers: serialArray,
      },
    };
    wsSend_request(connect, param);
    setShowPayment(true);
  };

  const handlePaymentIntant = async () => {
    console.log("payment intent called");
    const serialArray = [];
    selectedObjects.forEach((item) => {
      serialArray.push(item?.serial_number);
    });
    const payload = {
      webinarId: id,
      serialNumbers: serialArray,
      price: formData?.offerPrice || formData?.amount,
      offer: formData?.offer || "",
    };
    try {
      const response = await PostRequestAPI(
        App_url?.api?.createOrder,
        payload,
        accessToken
      );
      if (response?.status === 200) {
        console.log("Payment Response:", response);
        setShowPaymentModal(true)
        setClientSecretKey({ ...response?.data, payload: payload });
      } else {
        toast.error(response?.data?.error?.length ? response?.data?.error :  "Can not initiate payment right now");
      }
    } catch (error) {
      console.error("Upload failed:", error);
      // Handle upload error (e.g., display error message)
    }
  };

  if (showPayment && selectedObjects?.length > 0) {
    return (
      <div className="">
        <div className="m-0 p-3 overflow-hidden">
          <div className="col-md-12 white-background">
            <div className="col-md-12">
              <img
                src={App_url?.image?.bgDual}
                alt=""
                width={"100%"}
                className="header-text-img"
              />
              <div className="d-flex aic ms-2 header-text z-100">
                <img
                  src={App_url?.image?.CaretLeft}
                  alt=""
                  className="mb-2 pointer z-100"
                  onClick={() => {
                    setShowPayment(false);
                  }}
                />
                <h5 className="fw-500 fs-18px z-100">Confirm & Pay</h5>
              </div>
            </div>
            <div className="col-md-12 mt-4 ps-3 p-0">
              <div className="d-flex aic jcsb py-2 ps-3 z-100 mt-sm--1rem manage-md-mt-0">
                <Breadcrumb>
                  <Breadcrumb.Item
                    className="z-100"
                    onClick={() => {
                      handleRoute(App_url?.link?.home);
                    }}
                  >
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    className="z-100"
                    onClick={() => {
                      setShowPayment(false);
                    }}
                  >
                    Book Now
                  </Breadcrumb.Item>
                  <Breadcrumb.Item className="z-100" active>
                    Confirm and pay
                  </Breadcrumb.Item>
                </Breadcrumb>
                <h6
                  className={`pe-4 z-100 title ${width < 620 ? "fs-13px" : ""}`}
                >
                  {parseInt(productDetail?.booking?.length - closedCount) ===
                  0 ? (
                    "No Seats Available"
                  ) : (
                    <>
                      {parseInt(productDetail?.booking?.length - closedCount)}{" "}
                      {parseInt(
                        productDetail?.booking?.length - closedCount
                      ) === 1
                        ? "Seat"
                        : "Seats"}{" "}
                      {"  "}
                      Available Only
                    </>
                  )}
                </h6>
              </div>
              <div className="row m-0">
                <div className="col-md-12 p-0">
                  <div className="row m-0">
                    <div className="col-md-6 col-lg-4 col-12">
                      <div className="col-md-12">
                        <h5 className="fw-5 mt-3">Cart Details</h5>
                        <div className="cart-details custom-right-border pe-md-5 pe-0">
                          <div className="d-flex gap-3 border-bottom py-2">
                            <img
                              src={App_url?.image?.webinarName}
                              alt="webinarName"
                              loading="lazy"
                            />
                            <div className="d-flex flex-column">
                              <span className="sub-text fs-14px">
                                Webinar Name{" "}
                              </span>
                              <h6 className="fs-14px fw-5">
                                {productDetail?.data?.webinar_title}
                              </h6>
                            </div>
                          </div>
                          <div className="d-flex gap-3 border-bottom py-2">
                            <img
                              src={App_url?.image?.webinarDate}
                              alt="webinarDate"
                              loading="lazy"
                            />
                            <div className="d-flex flex-column">
                              <span className="sub-text fs-14px">
                                Seat No.{" "}
                              </span>
                              <h6 className="fs-14px fw-5">
                                {selectedObjects
                                  ?.map((item) =>
                                    item?.serial_number.toLocaleString()
                                  )
                                  .join(", ")}
                              </h6>
                            </div>
                          </div>
                          <div className="d-flex gap-3 py-2">
                            <img
                              src={App_url?.image?.webinarPrice}
                              alt="price"
                              loading="lazy"
                            />
                            <div className="d-flex flex-column">
                              <span className="sub-text fs-14px">
                                Price Per Ticket{" "}
                              </span>
                              <h6 className="fs-14px fw-5">
                                ${productDetail?.data?.ticket_price}
                              </h6>
                            </div>
                          </div>
                          <div className="bg-total br-6px">
                            <button className="w-100 btn d-flex jcsb">
                              <span className="fw-5">Total</span>
                              <span
                                className={`fw-5 ${
                                  formData?.offer ? "strikethrough" : ""
                                } relative`}
                              >
                                ${" "}
                                {parseFloat(
                                  productDetail?.data?.ticket_price *
                                    selectedObjects?.length
                                )}
                              </span>
                            </button>
                          </div>
                          {productDetail?.data?.offer &&
                            formData?.offer &&
                            selectedObjects?.length > 1 && (
                              <div className="box-pink-2 d-flex aic jcsb my-2 px-4 pt-10px pb-10px fw-500 br-8px">
                                <span className="text-capitalize">
                                  {" "}
                                  {productDetail?.data?.offer === "3+1"
                                    ? `Offer Price For Buy 4 Ticket and Pay Only for 3`
                                    : "Offer Price for More than One Ticket"}{" "}
                                  <span className="">
                                    {window.screen.width < 620
                                      ? ` $ ${formData?.offerPrice}`
                                      : ""}
                                  </span>
                                </span>
                                {window.screen.width > 620 && (
                                  <span>$ {formData?.offerPrice}</span>
                                )}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4 col-12">
                      <div className="col-md-12">
                        <h5 className="fw-5 mt-3">
                          Please send payment according to selection
                        </h5>
                        <div className="payment-detail">
                          <div
                            onClick={() => {
                              setFormData((prev) => ({
                                ...prev,
                                payment: "Venmo",
                              }));
                            }}
                            className="d-none pointer my-2 d-flex br-10px border pt-10px pb-10px jcsb aic"
                          >
                            <div className="ms-2">
                              <img
                                src={App_url?.image?.vemo}
                                alt="vemo"
                                loading="lazy"
                              />
                              <span className="fw5 ms-2">Venmo</span>
                            </div>
                            <div className="me-2">
                              <img
                                src={
                                  formData?.payment === "Venmo"
                                    ? App_url?.image?.checked
                                    : App_url?.image?.unchecked
                                }
                                alt="vemo"
                                loading="lazy"
                                height={20}
                                width={20}
                              />
                            </div>
                          </div>
                          <div
                            onClick={() => {
                              setFormData((prev) => ({
                                ...prev,
                                payment: "Paypal",
                              }));
                            }}
                            className="d-none pointer my-2 d-flex br-10px border pt-10px pb-10px jcsb aic"
                          >
                            <div className="ms-2">
                              <img
                                src={App_url?.image?.paypal}
                                alt="paypal"
                                loading="lazy"
                              />
                              <span className="fw5 ms-2">Paypal</span>
                            </div>
                            <div className="me-2">
                              <img
                                src={
                                  formData?.payment === "Paypal"
                                    ? App_url?.image?.checked
                                    : App_url?.image?.unchecked
                                }
                                alt="paypal"
                                loading="lazy"
                                height={20}
                                width={20}
                              />
                            </div>
                          </div>
                          <div
                            onClick={() => {
                              setFormData((prev) => ({
                                ...prev,
                                payment: "CashApp",
                              }));
                            }}
                            className="d-none pointer my-2 d-flex br-10px border pt-10px pb-10px jcsb aic"
                          >
                            <div className="ms-2">
                              <img
                                src={App_url?.image?.cashApp}
                                alt="cashApp"
                                loading="lazy"
                              />
                              <span className="fw5 ms-2">CashApp</span>
                            </div>
                            <div className="me-2">
                              <img
                                src={
                                  formData?.payment === "CashApp"
                                    ? App_url?.image?.checked
                                    : App_url?.image?.unchecked
                                }
                                alt="cashApp"
                                loading="lazy"
                                height={20}
                                width={20}
                              />
                            </div>
                          </div>
                          <button
                            className="d-none btn yellow-bg w-100 fw-6"
                            onClick={() => {
                              showSignInSuccessModal();
                            }}
                          >
                            Payment $ {formData?.offerPrice} Now{" "}
                          </button>
                          {/* paypal start */}
                          {/* <div className="Paypal mt-2 d-none">
                            <PayPalScriptProvider
                              options={{
                                clientId: App_url?.PAYPAL_CLIENT_ID,
                                currency: "USD",
                                disableFunding: "paylater,card,giropay,sepa",
                                enableFunding: ["venmo"],
                              }}
                            >
                              {" "}
                              <PayPalButtons
                                style={{
                                  shape: "rect",
                                  layout: "vertical",
                                  color: "gold",
                                  label: "paypal",
                                }}
                                createOrder={showSignInSuccessModal}
                                onApprove={async (data, actions) => {
                                  try {
                                    const serialArray = [];
                                    selectedObjects.forEach((item) => {
                                      serialArray.push(item?.serial_number);
                                    });
                                    const payload = {
                                      webinarId: id,
                                      serialNumbers: serialArray,
                                      price:
                                        formData?.offerPrice ||
                                        formData?.amount,
                                      offer: formData?.offer || "",
                                      orderID: data?.orderID,
                                    };

                                    const response = await fetch(
                                      `${process.env.REACT_APP_API_URL}api/payment/capture_order`,
                                      {
                                        method: "POST",
                                        headers: {
                                          "Content-Type": "application/json",
                                          Authorization: `Bearer ${accessToken}`,
                                        },
                                        body: JSON.stringify(payload),
                                      }
                                    );

                                    const orderData = await response.json();
                                    const errorDetail = orderData?.details?.[0];
                                    if (
                                      errorDetail?.issue ===
                                      "INSTRUMENT_DECLINED"
                                    ) {
                                      return actions.restart();
                                    } else if (errorDetail) {
                                      throw new Error(
                                        `${errorDetail.description} (${orderData.debug_id})`
                                      );
                                    } else {
                                      const transaction =
                                        orderData.purchase_units[0].payments
                                          .captures[0];
                                      setMessage(
                                        `Transaction ${transaction.status}: ${transaction.id}. See console for all available details`
                                      );
                                      if (orderData?.status === "COMPLETED")
                                        dispatch(
                                          setShowSuccessModal({
                                            show: "BOOKINGSUCCESS",
                                            data: payload?.price,
                                            msg:false
                                          })
                                        );
                                    }
                                  } catch (error) {
                                    console.error(error);
                                    setMessage(
                                      `Sorry, your transaction could not be processed...${error}`
                                    );
                                  }
                                }}
                              />
                            </PayPalScriptProvider>
                            <Message content={message} />
                          </div> */}

                          {/* paypal end */}
                          {/* <div className="CashApp d-none">
                            <CashApp />
                          </div> */}
                              <div className="cod mt-2">
                            <button
                              className="fs-17px btn bg-black text-white w-100 fw-5"
                              onClick={(e) => {
                                handlePaymentIntant(e, "CASHAPP");
                              }}
                            >
                              Stripe
                            </button>
                          </div>
                          <div className="cod mt-2">
                            <button
                              className="fs-17px btn bg-primary text-white w-100 fw-5"
                              onClick={(e) => {
                                handleCODOrder(e, "VENMO");
                              }}
                            >
                              Venmo
                            </button>
                          </div>
                          <div className="cod mt-2">
                            <button
                              className="fs-17px btn bg-pink  w-100 fw-5"
                              onClick={(e) => {
                                handleCODOrder(e, "ZELLE");
                              }}
                            >
                              Zelle
                            </button>
                          </div>
                          {clientSecretKey &&
                            clientSecretKey?.client_secret && (
                              <React.Fragment>
                                <Elements
                                  stripe={stripePromise}
                                  options={{
                                    clientSecret:
                                      clientSecretKey?.client_secret,
                                  }}
                                >
                                  <Checkout
                                    options={{
                                      clientSecret:
                                        clientSecretKey?.client_secret,
                                    }}
                                    customerDetails={selfInfo}
                                    data={{ show: showPaymentModal, type: "payment",payload:{...clientSecretKey} }}
                                    formData={formData}
                                    onClose={() => {
                                      setShowPaymentModal(false);
                                      setClientSecretKey("");
                                    }}
                                   />
                                </Elements>
                              </React.Fragment>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BookingSuccessModal />
      </div>
    );
  } else {
    return (
      <div className="">
        <div className="m-0 p-3 overflow-hidden">
          <div className="col-md-12 white-background">
            <div className="col-md-12">
              <img
                src={App_url?.image?.bgDual}
                alt="background image"
                loading="lazy"
                width={"100%"}
                className="header-text-img"
              />
              <div className="d-flex aic ms-2 header-text">
                <img
                  src={App_url?.image?.CaretLeft}
                  alt="caretLeft"
                  loading="lazy"
                  className="mb-2 pointer z-100"
                  onClick={() => {
                    handleRoute(App_url?.link?.home);
                  }}
                />
                <h5 className="fw-500 fs-18px z-100">Select Your Seat</h5>
              </div>
            </div>
            <div className="col-md-12 mt-4 p-0 ps-3 manage-md-pt-3">
              <div className="d-flex aic jcsb pt-md-2 pt-0 py-2">
                <h6 className="ms-2 pl-4px z-100 title">
                  {productDetail?.data?.webinar_title}
                </h6>
                <h6
                  className={`pe-md-4 pe-0 z-100 title ${
                    width < 620 ? "fs-13px" : ""
                  }`}
                >
                  {productDetail?.data?.winner_number ? (
                    `Winner is ${productDetail?.data?.winner_number} Number`
                  ) : parseInt(productDetail?.booking?.length - closedCount) ===
                    0 ? (
                    "No Seats Available"
                  ) : (
                    <>
                      {parseInt(productDetail?.booking?.length - closedCount)}{" "}
                      {parseInt(
                        productDetail?.booking?.length - closedCount
                      ) === 1
                        ? "Seat"
                        : "Seats"}
                      {"  "}
                      Available Only
                    </>
                  )}
                </h6>
              </div>
              {loader ? (
                <div className="relative h-100px mt-5">
                  {" "}
                  <Loader />{" "}
                </div>
              ) : (
                <>
                  <div className="row mt-3 mt-md-0 m-0 manage-md">
                    <div className="col-md-12">
                      <div className="row m-0 col-reverse-sm">
                        <div className="col-12 col-md-7 col-lg-8">
                          <div className="row">
                            <h5
                              className="fw-500 mt-3"
                              style={{ marginLeft: "-0.7rem" }}
                            >
                              Select Seats{" "}
                            </h5>
                            {productDetail?.data?.offer && (
                              <div
                                className={`col-12 col-md-11 box-pink B32B85 my-2 px-4 pt-12px pb-12px fw-500 br-8px`}
                              >
                                {productDetail?.data?.offer === "3+1"
                                  ? "Buy 4 Ticket and Pay Only for 3 Offer Only for First 10 Customers"
                                  : `Buy more than one ticket and get $${productDetail?.data?.offer} off on every seat`}
                              </div>
                            )}
                          </div>
                          <div className="row">
                            {arrayOfObjects?.length > 0 &&
                              arrayOfObjects?.map((item, index) => {
                                return (
                                  <div
                                    className={`col-md-2 col-lg-1 col-2-5 mx-1 my-1 selection-box ${
                                      item?.serial_number ===
                                      parseInt(
                                        productDetail?.data?.winner_number
                                      )
                                        ? "winner"
                                        : ""
                                    } ${
                                      item?.status === "hold" ||
                                      item?.status === "own" ||
                                      item?.className === "confirmed"
                                        ? "cursor-not-allowed"
                                        : "pointer"
                                    } p-2 aic rounded ${item?.status}`}
                                    key={index}
                                    onClick={() => {
                                      handleSelect(item);
                                    }}
                                  >
                                    <div className="d-flex flex-column">
                                      <span className="text-left fw-600">
                                        {item?.serial_number}
                                      </span>
                                      <span className="word-wrap-break-word fs-13px selection-box-text">
                                        {item?.status === "selected"
                                          ? "Selected"
                                          : item?.status === "hold"
                                          ? "In a Cart"
                                          : item?.status === "closed"
                                          ? item?.user_name
                                          : item?.status === "own"
                                          ? `${item?.user_name}`
                                          : ""}
                                      </span>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                        <div className="col-md-4 col-12 p-0 mb-mt-0 mt-4">
                          <div className="col-md-12 col-12">
                            <div className="d-flex aic gap-2 flex-wrap mb-2">
                              <Carousel controls={true}>
                                {productDetail?.data?.product_images !==
                                  undefined &&
                                  productDetail?.data?.product_images?.length >
                                    0 &&
                                  productDetail?.data?.product_images?.map(
                                    (image, index) => (
                                      <Carousel.Item key={index}>
                                        <div className="carousel-image-container pointer">
                                          <img
                                            src={`${App_url?.BACKEND_URL}uploads/${image}`}
                                            className="rounded"
                                            alt="product image"
                                            loading="lazy"
                                            width={
                                              window.screen.width > 620
                                                ? "410px"
                                                : "100%"
                                            }
                                            height={"200px"}
                                          />
                                        </div>
                                      </Carousel.Item>
                                    )
                                  )}
                              </Carousel>
                            </div>
                            <h5 className="text-yellow fw-5">
                              Cost: $ {productDetail?.data?.product_price}
                            </h5>
                            <h5 className="fw-5">Description:</h5>
                            <h6
                              className="col-12 col-lg-10 description"
                              dangerouslySetInnerHTML={{
                                __html: productDetail?.data?.description,
                              }}
                            />
                            <button
                              className={`btn yellow-bg mt-2 ${
                                width > 620 ? "w-85" : "w-100"
                              } fw-6`}
                              onClick={handleBookingChange}
                            >
                              {productDetail?.data?.winner_number
                                ? `Winner is ${productDetail?.data?.winner_number} Number`
                                : parseInt(
                                    productDetail?.booking?.length - closedCount
                                  ) === 0
                                ? "All Seats Are Booked"
                                : `Book Selected ${
                                    selectedObjects?.length
                                  } Seat${
                                    selectedObjects?.length !== 1 ? "s" : ""
                                  }`}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Detail;
